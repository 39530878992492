<div class="mainWraper">
  <div class="textWraper">

    <h1>O szkole</h1>

    <p>OSK Bielecki powstał w 2011 roku. W tym czasie wyszkoliliśmy setki kierowców. PNaszą szkołę wyróżnia indywidualne
      podejście do każdego kursanta, cierpliwość, zaangażowanie, profesjonalizm. Zapisując się u nas na kurs zyskujesz
      pewność, że nasi instruktorzy nie tylko przygotują Cię do egzaminu na prawo jazdy, ale też nauczą jak radzić sobie
      w trudnych warunkach i sytuacjach na drodze.</p>

    <p>Dodatkowo na kursie dowiesz się jak:</p>
    <ul>
      <li>zatankować samochód,</li>
      <li>korzystać z myjni samochodowej (bezdotykowej),</li>
      <li>radzić sobie na wielopoziomowych parkingach podziemnych i otwartych.</li>
    </ul>
    <p>Zapraszamy na kurs nauki jazdy, a także jazdy doszkalające do Ośrodka Szkolenia Kierowców Bielecki.</p>
  </div>
</div>
