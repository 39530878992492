<div class="mainWraper">
  <div class="textWraper">

      <h2 class="contactInfo contactInfoTitle">OSK Bielecki</h2>
      <p class="contactInfo contactInfoName">Konrad Bielecki</p>
      <p class="contactInfo">01-318 Warszawa</p>
      <p class="contactInfo">ul. Szobera 5 lok 6c</p>
      <p class="contactInfo">Kontakt z Biurem tylko droga telefoniczna, lub mailem.<br class="br">Informacje wysłane sms nie będą uznawane przez biuro.</p>
      <p class="contactInfo">Biuro czynne od poniedziałku do piątku <br class="br"> w godzinach 15:00-19:00</p>
      <br>
      <p class="contactInfo contactInfoLink"><a href="tel:518367290" class="standardLink"><img src="assets/icons/phone.png" alt="Phone icon" class="contactIcon">518367290</a></p>
      <p class="contactInfo contactInfoLink" ><a href="mailto:oskbielecki@o2.pl" class="standardLink"><img src="assets/icons/mail.png" alt="Mail icon" class="contactIcon">oskbielecki@o2.pl</a></p>
      <br>
      <p class="contactInfo">Konto Bankowe w Mbanku</p>
      <p class="contactInfo">59 1140 2004 0000 3402 8216 5315</p>
      <p class="contactInfo">W tytule przelewu podać imie nazwisko, nazwa usługi.</p>
      <p class="contactInfo">Kurs podstawowy\przyspieszony\VIP</p>
      <p class="contactInfo">Jazdy dodatkwe\uzupełniające.</p>
      <br>
      <p class="contactInfo">Zapisując się na kurs akceptujesz postanowienia regulaminu oraz cennik naszej szkoły jazdy.</p>
    </div>
  <!-- <div class="office">
    <p class="contactInfo officeInfo">Biuro czynne od poniedziałku do piątku <br class="br"> w godzinach 15-19</p>
  </div> -->
  <img class="kbPicture" src="assets/BIELECKIi-3.png">
</div>







