import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-statute',
  templateUrl: './statute.component.html',
  styleUrls: ['./statute.component.scss']
})
export class StatuteComponent implements OnInit {


  newDate = new Date();
  toTop = false;

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  @HostListener("document:scroll")
  scrollFunction()  {
    if(document.body.scrollTop > 0 || document.documentElement.scrollTop > 0){
      this.toTop = true;
    } else  {
      this.toTop = false;
    }
  }

}
