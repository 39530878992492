<div id="mainWraper">

    <div class="btn btn-three" (click)="onSwitchMenu()">
      <span>MENU</span>
    </div>

    <div class="mainMenu">
      <div class="menu" [class.showMenu]="isClicked" [class.hideMenu]="!isClicked">

        <div class="listTitleWraper">
          <a href="#oSzkole" class="navButton standardLink ">
            O SZKOLE
          </a>
        </div>

        <div class="listTitleWraper">
          <a href="#przedKursem" class="navButton standardLink">PRZED KURSEM
          </a>
        </div>

        <div class="listTitleWraper">
          <a href="#cennik" class="navButton standardLink">CENNIK
          </a>
        </div>

        <div class="listTitleWraper">
          <a href="#kontakt" class="navButton standardLink">KONTAKT
          </a>
        </div>

      </div>
    </div>
  
    <div class="firstSection">

      <div class="titileContactIcons">
        <a href="mailto:oskbielecki@o2.pl"><img class="mailTitleIcon titleIcon" src="assets/icons/mail5.png"
            alt="mail icon"></a>
        <a href="tel:518367290"><img class="phoneTitleIcon titleIcon" src="assets/icons/phone5.png"
            alt="phone icon"></a>
      </div>
      <a href="/" class="standardLink">
        <img src="assets/BIELECKIi-3.png" class="mainLogo">
      </a>

      <div *ngIf="toTop">
        <a href="#"><img class="toTopButtonImg" src="assets/icons/arrow.png">
        </a>
      </div>
    </div>


    <app-benefits id="oSzkole"></app-benefits>
    <app-trainings id="przedKursem"></app-trainings>
    <app-price id="cennik"></app-price>
    <app-contact id="kontakt"></app-contact>

    <!-- To top button -->
    
   


    <footer>
      {{ newDate | date: 'yyyy' }} &copy; OSK Konrad Bielecki. Wszelkie prawa zastrzeżone <br class="brFooter"> Twórca: <a href="mailto:wiktor.wazniewski@gmial.com" class="authorMail standardLink">Wiktor Ważniewski</a>
    </footer>

  </div>
