import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BenefitsComponent } from './benefits/benefits.component';
import { PriceComponent } from './price/price.component';
import { TrainingsComponent } from './trainings/trainings.component';
import { ContactComponent } from './contact/contact.component';
import { AppRoutingModule } from './app-routing.module';
import { StatuteComponent } from './statute/statute.component';
import { StartComponent } from './start/start.component';

@NgModule({
  declarations: [
    AppComponent,
    BenefitsComponent,
    PriceComponent,
    TrainingsComponent,
    ContactComponent,
    StatuteComponent,
    StartComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
