<div class="mainWraper">
  <a routerLink="/">
    <div class="btn btn-three">
      <span>WRÓĆ</span>
    </div>
  </a>

  <div class="textWraper">
    <div class="textWraperInner">
      <div class="headerWraper">
      <h1>REGULAMIN OŚRODKA SZKOLENIA KIEROWCÓW KONRAD BIELECKI</h1>
      <img src="assets/BIELECKIi.png" alt="logo" class="logo">
    </div>

      <h3>§ 1 Postanowienia ogólne</h3>
      <p>Zapisując się na kurs akceptujesz postanowienia regulaminu oraz cennik naszej szkoły jazdy</p>

      <h3>§ 2 Warunki przyjęcia na szkolenie</h3>
      <ol>
        <li>Szkolenie odbywa się na zasadach określonych ustawą z dnia 20 czerwca 1997 r.
          – Prawo o ruchu drogowym ( Dz.U. z 2005r. Nr 108, poz.908 ze zm.).</li>

        <li> Szkolenie może rozpocząć osoba, która ukończyła wymagany wiek dla danej kategorii: <br>
          a) 18 lat – dla kategorii B.</li>

        <li>Osoba, która nie ukończyła 18 lat, może przystąpić do szkolenia za pisemną
          zgodą rodziców lub opiekunów na 3 miesiące przed ukończeniem 18-tego roku życia.</li>

        <li>Szkolenie nie może rozpocząć osoba, w stosunku do której orzeczony został
          prawomocnym wyrokiem sądu lub orzeczeń innego organu uprawnionego do
          orzekania w sprawach o wykroczenia w trybie dyscyplinarnym zakaz prowadzenia
          pojazdów mechanicznych – w okresie obowiązywania tego zakazu.</li>
      </ol>
      <h3>§ 3 Obowiązki ośrodka</h3>
      <ol>
        <li>Ośrodek zobowiązuje się przeprowadzić szkolenie zgodne z Rozporządzeniem
          Ministra Transportu, Budownictwa i Gospodarki Morskiej z dnia 13 lipca 2012 r.
          (Dz.U. z dnia 14 września 2012 r. poz. 1019), możliwie jak najkrótszym czasie.</li>

        <li>W ramach szkolenia podstawowego Ośrodek zapewnia Osobie szkolonej
          wymaganą rozporządzeniem, o którym mowa w ust.1 ilość godzin lekcyjnych
          (1 godzina = 45 minut) szkolenia teoretycznego i godzin zegarowych
          (1 godzina = 60 minut) szkolenia praktycznego.</li>

        <li>Szkolenia praktyczne zawsze zaczynają się i kończą w oddziale firmy w Warszawie
          przy ul.Szobera 5 lok 6c, a ewentualny odbiór kursanta z np. pracy, szkoły
          jest możliwy tylko przy wyborze rodzaju kursu VIP.</li>

        <li>Ośrodek zobowiązuje się wydać Osobie szkolonej komplet dokumentów
          stanowiących o ukończeniu szkolenia do dwóch tygodni po pozytywnie zdanym
          egzaminie wewnętrznym o ile uregulowane zostaną wszelkie zobowiązania
          i opłaty wobec Ośrodka zgodnie z umową.</li>

        <li>W przypadku zaistnienia sytuacji losowej, uniemożliwiającej przeprowadzenie
          części szkolenia teoretycznej lub praktycznej, Ośrodek zobowiązuje się do
          niezwłocznego powiadomienia osoby szkolonej.</li>
      </ol>

      <h3>§ 4 Obowiązki osoby szkolonej</h3>
      <ol>
        <li>Osoba szkolona zobowiązuje się dołożyć wszelkich starań, aby ukończyć
          szkolenie o którym mowa w §1 ust.2 pkt.c niniejszego regulaminu, w jak
          najkrótszym czasie.</li>

        <li>Osoba szkolona zobowiązuje się do systematycznego uczęszczania na zajęcia
          teoretyczne (wykłady) zgodnie z przedstawionym harmonogramem oraz na
          zajęcia praktyczne (jazdy) w terminie.</li>

        <li>Zobowiązuje się sumiennie i systematycznie przygotować do zajęć.</li>

        <li>Instruktor prowadzący ma prawo w trakcie szkolenia praktycznego zobowiązać
          Kursanta, który wykazuje ogromne braki w wiedzy teoretycznej do jej
          uzupełnienia.</li>

        <li>Osoba szkolona zobowiązana jest stawić się punktualnie na wszystkie zajęcia
          z dowodem osobistym lub innym dokumentem tożsamości.</li>

        <li>Osoba szkolona zobowiązuje się do poinformowania o swojej nieobecności na
          umówionym wcześniej szkoleniu praktycznym, nie później niż 24 godzin
          przed terminem tego szkolenia. W przypadku odwołania zajęć w terminie
          późniejszym lub nie stawienia się na umówione zajęcia praktyczne w ciągu 15 minut
          sytuacja traktowana jest jako nieobecność, co do której obowiązuje ust.7 niniejszego
          regulaminu.</li>

        <li>Kontakt z Biurem tylko droga telefoniczna, lub mailem. Informacja wysłane sms nie będą
          uznawane przez biuro.</li>

        <li>W przypadku niewywiązywania się z obowiązku, o którym mowa w ust. 6, osoba
          szkolona zobowiązuje się dokonać opłaty za nie odbyte szkolenie zgodnej z
          zapisami zawartymi w umowie.</li>

        <li>Osoba szkolona ma prawo zmiany instruktora w trakcie trwania szkolenia praktycznego.</li>

        <li>W przypadku przystąpienia osoby szkolonej do szkolenia praktycznego w stanie
          nietrzeźwości, w stanie po użyciu alkoholu lub środka działającego podobnie do alkoholu,
          osoba szkolona automatycznie zostaje skreślona z listy osób szkolonych bez prawa
          do zwrotu opłaty za szkolenie, dodatkowo ośrodek ma prawo poinformować o zdarzeniu
          policję.</li>

        <li>W przypadku umyślnego stworzenia, przez osobę szkolną zagrożenia zdrowia lub życia
          innych uczestników ruchu drogowego osoba szkolona automatycznie zostaje skreślona
          z listy osób szkolonych bez prawa do zwrotu opłaty za szkolenie.</li>

        <li>Osoba szkolona jest zobowiązana do poszanowania i nie niszczenia mienia
          powierzonego mu w czasie trwania kursu, a w przypadku umyślnego zniszczenia
          zobowiązuję się do pokrycia kosztów ewentualnych napraw lub wymiany.</li>
      </ol>
      <h3>§ 5 Przerwanie szkolenia</h3>

      <ol>
        <li>Każdej ze stron przysługuje prawo do przerwania szkolenia zgodnie z
          Dz.U. z dnia 14 września 2012r. poz. 101.</li>

        <li>W przypadku rezygnacji przez kursanta przed rozpoczęciem szkolenia, ośrodek odlicza
          10% kosztów manipulacyjnych.</li>

        <li>Wypowiedzenie umowy bez winy ośrodka skutkuje pobraniem opłaty 90zł za każdą
          godzinę odbytego szkolenia praktycznego.</li>

        <li>W przypadku utraty lub zagubienia karty przeprowadzonych zajęć i konieczności
          wystawienia duplikatu karty ośrodek pobiera opłatę w wysokości 100 złotych</li>
      </ol>

      <h3>§ 6 Wznowienie kursu po przerwie</h3>

      <ol>
        <li>Kursant, który przerwał szkolenie może na nie powrócić bez żadnych przeszkód
          w terminie do 24 miesięcy od daty przerwania kursu tj. daty odbycia ostatnich zajęć.</li>

        <li>Jeżeli w naszej szkole nastąpiła podwyżka ceny kursu, aby wznowić kurs należy dokonać dopłaty do nowo
          obowiązującej ceny.</li>

        <li>Z przyczyn losowych kurs na każdym etapie można zawiesić pod warunkiem opłacenia w całości kosztu kursu.
        </li>

        <li>Wznowienie kursu po jego zawieszeniu może nastąpić nie później niż 24 miesięcy od daty ostatnich zajęć.</li>

        <li>Po upływie 2 lat od ostatnich zajęć wznowienie kursu oraz wszelkie zwroty kosztów czy reklamacje nie są
          możliwe.
          Profil Kandydata na Kierowcę (PKK) odsyłany jest do odpowiedniego Wydziału Komunikacji z adnotacją o
          nieukończeniu
          szkolenia.</li>
      </ol>

      <h3>§7 Opłaty</h3>

      <ol>
        <li>Opłata za szkolenie, jej formy oraz inne opłaty są zgodne z umową. W przypadku
          nie określenia opłaty w umowie obowiązujący staje się cennik aktualny na dzień
          zawarcia umowy.</li>

        <li>Osoba szkolona może płatność za szkolenie rozłożyć na nieoprocentowane raty z tym,
          że przed przystąpieniem do szkolenia osoba szkolona musi wypłacić nie mniej niż 1000 zł,
          a całość opłaty za kurs osoba szkolona musi dokonać najpóźniej przed rozpoczęciem
          dwudziestej godziny jazd praktycznych.</li>

        <li>W przypadku nieuregulowania płatności za szkolenie zgodnie z umową Ośrodek
          potraktuje to jako wstrzymanie szkolenia.</li>
      </ol>

      <h1>Regulamin jazd doszkalających </h1>
      <ol>
        <li>Klient, który po wykupieniu w naszej szkole pakietu minimum 10 godzin doszkalających
          i wykorzystaniu ich, może dokupić jeszcze dodatkowe pojedyncze godziny w cenie
          odpowiadającej cenie godziny z wykupionego pakietu.</li>

        <li>Klientowi, który wykupuje godziny pojedynczo oraz który wykupił pakiety mniejsze niż
          10 godzin, prawo takie nie przysługuje.</li>

        <li>Pojedyncze wykupione godziny nie sumują się w pakiety.</li>

        <li>Opłata za jazdy doszkalające podlega zwrotowi do 6 miesięcy od dnia ich wykupienia.
          Szkoła dolicza wtedy 30 % opłaty manipulacyjnej.</li>

        <li>Wykupione jazdy doszkalające należy zrealizować do 6 miesięcy od dnia ich zakupu.</li>

        <li>Klient ma prawo po wykupieniu i odbyciu w naszej Szkole pierwszej lekcji doszkalającej
          zdecydować się na wykup pakietu lub zmianę pakietu, dopłacając tylko różnicę cenową
          pomiędzy wybranymi przez siebie wariantami.</li>

        <li>Odwołać zaplanowane jazdy Klient może maksymalnie do 24 godzin wcześniej
          telefonicznie u swojego instruktora lub w przypadku pierwszej lekcji w naszej Szkole,
          która go na te zajęcia umawiała.</li>

        <li>Zaplanowane jazdy doszkalające powinny być opłacone do jednego dnia roboczego
          poprzedzającego ustalony termin. W przypadku przelewu, pieniądze powinny
          być zaksięgowane na naszym koncie do tego właśnie dnia.</li>

        <li>Fakt opłacenia jazd doszkalających jest dla Szkoły potwierdzeniem akceptacji regulaminu.</li>
      </ol>
    </div>
  </div>

  <div *ngIf="toTop">
    <a href="regulamin#"><img class="toTopButtonImg" src="assets/icons/arrow.png">
    </a>
  </div>

  <div>
    <a href="/"><img class="toLeftButtonImg" src="assets/icons/arrow-left.png">
    </a>
  </div>

  <div class="stFooter">
    <div class="textFooterWraper">
      {{ newDate | date: 'yyyy' }} &copy; OSK Konrad Bielecki. <br class="brFooterSecond"> Wszelkie prawa zastrzeżone <br class="brFooter"> Twórca:
      <a href="mailto:wiktor.wazniewski@gmial.com" class="authorMail standardLink">Wiktor Ważniewski</a>
    </div>
  </div>
</div>
