<div class="mainWraper">
  <div class="textWraper">
    <h1>Cennik</h1>
    <h2>Kurs prawa jazdy kat. B - Hyundai i20:</h2>

    <p>
      Kurs Podstawowy – 3000 zł (możliwość rozłożenia na raty)<br class="br">
      - 30 godzin teorii oraz 30 godzin praktyki <br class="br">
      - Zajęcia praktyczne odbywają się dwa razy w tygodniu po dwie godziny.<br class="br">
    </p>
    <p>
      Kurs Przyspieszony – 3700 zł (możliwość rozłożenia na raty)<br>
      - 30 godzin teorii oraz 30 godzin praktyki.<br>
      - Zajęcia odbywają się pięć razy w tygodniu po dwie godziny.<br>
    </p>
    <p>
      Kurs VIP 4000 zł (możliwość rozłożenia na raty) <br>
      - 30 godzin teorii oraz 30 godzin praktyki<br>
      - Zajęcia praktyczne odbywają się pięć razy w tygodniu po dwie godziny.<br>
      - Rozpoczynamy oraz kończymy w miejscu wyznaczonym przez kursanta, na terenie Warszawy. <br>
    </p>

    <p>Materiały do samodzielnej nauki w domu + dostęp do teorii online-GRATIS.</p>

    <h2>Jazdy Doszkalające/uzupełniające kat.B Hyundai i20</h2>
    <p>
      110zł za 1 godzine zegarowa od poniedziałku do piątku. <br>
      130zł za 1 godzine zegarowa soboty, niedziele. <br>
      1050zł pakiet 10 godzin zegarowych, od poniedziałku do piątku.<br>
    </p>
    <p>
      Wynajęcie auta z instruktorem dla osób posiadających prawo jazdy.<br>
      110zł za 1 godzine zegarowa od poniedziałku do piątku. <br>
      130zł za 1 godzine zegarowa soboty, niedziele. <br>
      1050zł pakiet 10 godzin zegarowych, od poniedziałku do piątku. <br>
    </p>
    <p>
      Podstawienie auta na egzamin państwowy:<br>
      Gratis- w pierwszym podejściu do egzaminu zaraz po ukończeniu kursu,<br>
      kazde następne podstawienie- 150 zł
    </p>

  </div>
</div>
