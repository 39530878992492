import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent {

  newDate = new Date();
  toTop = false;
  isClicked = true;

  onSwitchMenu(): void  {
    this.isClicked = !this.isClicked;
  }

  @HostListener("document:scroll")
  scrollFunction()  {
    if(document.body.scrollTop > 0 || document.documentElement.scrollTop > 0){
      this.toTop = true;
    } else  {
      this.toTop = false;
    }
  }
}
