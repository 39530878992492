<div class="mainWraper">
  <div class="textWraper">
    <h1>Przed kursem</h1>

    <ol>
      <li>Należy wykonać badania z zakresu medycyny pracy. W celu pomocy przy wyborze specjalisty prosimy kontaktować
        się z naszym biurem pod nr tel.: 518-367-290.</li>
      <li>Kolejnym krokiem jest wykonanie kolorowych zdjęć, które powinny być wykonane nie dłużej niż 6 miesięcy przed
        złożeniem wniosku. Nie może być na nim widocznych pikseli (Mają być ostre i posiadać naturalny kontrast), tło z
        kolei-jasne, jednolite. Wymagane wymiary fotografii to 35×45 mm.
      </li>
      <li>Następnie należy udać się wydziału komunikacji, urzędu właściwego z uwagi na miejsce zamieszkania. Po złożeniu
        wniosku, bezpłatnie zostanie nadany indywidualny numer, tak zwany Profil Kandydata na Kierowcę (PKK). Do
        wyrobienia PKK jest potrzebne:-zaświadczenie lekarskie - jedno zdjęcie.
      </li>
      <li>Jeśli posiadasz już numer PKK, to znaczy, że możesz się zapisać na kurs w naszej szkole. Wybierz rodzaj kursu
        i termin, który Ci najbardziej pasuje. Zadzwoń do nas (tel.: 518-367-290) lub odwiedź nasze biuro.
      </li>
    </ol>

    <p>Zapisując się na kurs akceptujesz postanowienia regulaminu oraz cennik naszej szkoły jazdy.</p>

    <div class="regButtonsWraper">
      <a routerLink="/regulamin" class="standardLink">
        <div class="btn btn-three">
          <span>Regulamin</span>
        </div>
      </a>

      <a href="assets/regulamin/Regulamin_OSK_Bielecki.pdf" class="standardLink">
        <div class="btn btn-three iconBtn">
          <span class="regText">Regulamin PDF </span><img src="assets//icons/pdf.png" class="formatIcon">
        </div>
      </a>
      <a href="assets/regulamin/Regulamin_OSK_Bielecki.docx" class="standardLink">

        <div class="btn btn-three iconBtn">
          <span class="regText">Regulamin DOCX </span><img src="assets//icons/doc.png" class="formatIcon">
        </div>
      </a>
    </div>

  </div>

</div>
